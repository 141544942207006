import React, { useEffect } from "react";
// layout
import Layout from "../../layout";
// material ui
import { Box, Grid } from "@mui/material";
// images
import {
  AboutUsImage,
  AboutUsTab1Image,
  AboutUsTab2Image,
  AboutUsTab3Image,
  AboutBanner,
} from "./../../assets/images";
// styles
import "./../../styles/about-us.css";
// animation
import { Fade } from "react-reveal";
// components
import {
  Breadcrumbs,
  StepCard,
  Tabs,
  GetInTouch,
  ContactUsSection,
} from "./../../components";
// icons
import {
  ExpectationsIcon,
  ExpectationsWhiteIcon,
  PresenceIcon,
  PresenceWhiteIcon,
  EconomicIcon,
  EconomicWhiteIcon,
  AboutUsCard1Icon,
  AboutUsCard2Icon,
  AboutUsCard3Icon,
  AboutUsCard4Icon,
} from "./../../assets/icons";
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const TabsList = [
    {
      icon: ExpectationsIcon,
      activeIcon: ExpectationsWhiteIcon,
      title: "Customer Expectations",
      title2: "",
      heading: "",
      description:
        "To surpassing customers’ expectations by offering excellent and timely business solutions to enhance the business in more effective way that will help to achieve your goals",
      image: AboutUsTab1Image,
    },
    {
      icon: PresenceIcon,
      activeIcon: PresenceWhiteIcon,
      title: "Online Presence",
      title2: "",
      heading: "",
      description:
        "In this ever evolving era of technologies, online presence Is imperative for all the organizations. And the first step to online presence is having a website. A website itself is utilized to score many different marketing strategies to commemorate the business growth.",
      image: AboutUsTab2Image,
    },
    {
      icon: EconomicIcon,
      activeIcon: EconomicWhiteIcon,
      title: "Economic Gain",
      title2: "",
      heading: "",
      description:
        "The best thing about website is that your customer can get to know your products at their convenience. Websites help to provoke engagement that inspires and creates economic gain for your business.",
      image: AboutUsTab3Image,
    },
  ];
  const CardList = [
    {
      icon: AboutUsCard1Icon,
      title: "Global Foot Print",
      description:
        "Our operations spans to 4 continents for high performance solutions and delivery at your organization pace.",
    },
    {
      icon: AboutUsCard2Icon,
      title: "Skilled Resources",
      description:
        "Skilled business and technical resources providing cost affective and efficient solutions. Leading towards unmatchable success with team expertise.",
    },
    {
      icon: AboutUsCard3Icon,
      title: "Project Management",
      description:
        "Perfection from planning to execution, the competence of professionalism, esteem in meeting deadline.",
    },
    {
      icon: AboutUsCard4Icon,
      title: "Superior Value",
      description:
        "With our Innovative hybrid service model we provide agility, affordability & perfection. Thus ensuring on site agility and offshore affordability",
    },
  ];
  return (
    <Layout>
      {/* <Breadcrumbs heading="About Us" /> */}
      <img src={AboutBanner} width="100%" />
      <div className="pages-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
            <div className="pages-section-data-main">
              <Fade bottom delay={400}>
                <h1 className="pages-section-heading">WHAT WE PROVIDE</h1>
              </Fade>
              <Fade bottom delay={800}>
                <p className="pages-section-description">
                  GMITSTech is a leader in next-generation digital services and
                  consulting. We have enabled clients in more than 20 countries
                  to start their digital transformation journey. We have our
                  Operational Offices across 5 countries.
                  <br />
                  With over 5 years of experience we expertly steer our clients
                  through their digital journey. We achieve this by enabling the
                  enterprise with an AI-powered core that helps prioritize the
                  execution of change. Our objective is to enable agile business
                  transformation for higher performance of the organization. Our
                  always-on learning agenda drives their continuous improvement
                  through building and transferring digital skills. Among AI and
                  ML we have expertise in IT managed services, Cloud solutions
                  and automation.
                </p>
              </Fade>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <div className="about-us-section-1-image-main">
                <Fade bottom delay={1200}>
                  <img
                    src={AboutUsImage}
                    alt="about us"
                    className="about-us-section-1-image"
                  />
                </Fade>
              </div>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <div>
        <StepCard
          heading="WHY GMITS ?"
          subHeading="We add value by "
          subHeading2="providing 24/7"
          description=""
          subHeading3="support to our business partners."
          CardList={CardList}
        />
      </div>
      <div className="home-section-6">
        <Tabs heading="Our " heading2="Mission" TabsList={TabsList} />
      </div>
      <GetInTouch />
      <ContactUsSection />
    </Layout>
  );
};
export default AboutUs;
