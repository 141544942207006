import React from "react";
// material ui
import { Box, Grid, Button } from "@mui/material";
// styles
import "./../../styles/contact-us-section.css";
// ifrme
import Iframe from "react-iframe";
// animation
import { Fade } from "react-reveal";
const ContactUsSection = () => {
  return (
    <div className="contact-us-section-container">
      <Fade bottom delay={400}>
        <h1 className="contact-us-section-heading">CONTACT US</h1>
        <h1 className="contact-us-section-heading-2">
          Got A Question? <span>Contact Us</span>
        </h1>
      </Fade>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={5}>
          <Grid item xl={2} lg={1} md={1} sm={12} xs={12}></Grid>
          <Grid item xl={4} lg={4} md={5} sm={6} xs={12}>
            <div className="contact-section-map-main">
              <div className="contact-section-map">
                <Iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7229.550454137204!2d55.219352!3d25.041701!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb6dbc77050c384b5!2sGMITS%20TECH%20LLC!5e0!3m2!1sen!2sus!4v1638029515287!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                />
              </div>
            </div>
          </Grid>
          <Grid item xl={4} lg={6} md={5} sm={6} xs={12}>
            <Fade bottom delay={400}>
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="contact-input"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="contact-input"
              />
              <input
                type="number"
                name="phone"
                placeholder="Phone"
                className="contact-input"
              />
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                className="contact-input"
              />
              <textarea
                placeholder="Type your message here..."
                className="contact-textarea"
              ></textarea>
              <Button
                type="text"
                fullWidth={true}
                className="contact-form-submit-button"
              >
                Sumbit Inquiry
              </Button>
            </Fade>
          </Grid>
          <Grid item xl={2} lg={1} md={1} sm={12} xs={12}></Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default ContactUsSection;
