import React, { useEffect } from "react";
// layout
import Layout from "../../layout";
// components
import { Breadcrumbs, ContactUsSection } from "./../../components";
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Breadcrumbs heading="Contact Us" />
      <ContactUsSection />
    </Layout>
  );
};
export default ContactUs;
