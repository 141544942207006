import React from "react";
// components
import { SubHeader, Navbar, Footer, SubFooter, Drawer } from "./../components";
//styles
import "./../styles/layout.css";
//icons
import { ImWhatsapp } from "react-icons/im";
import { FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import { BsYoutube, BsTwitter, BsInstagram } from "react-icons/bs";
// material ui
import { Button } from "@mui/material";
const Layout = (props) => {
  return (
    <div className="layout-container">
      <div className="share-container">
        <Button
          variant="text"
          target="_blank"
          href="//api.whatsapp.com/send?phone=+971 50 987 3721&text=Hi I am available"
          className="share-button share-whatsapp-button"
        >
          <ImWhatsapp />
        </Button>
        <Button
          variant="text"
          target="_blank"
          href="https://www.linkedin.com/in/gmitstech/"
          className="share-button share-linkedin-button"
        >
          <FaLinkedinIn />
        </Button>
        <Button
          variant="text"
          target="_blank"
          href="https://www.youtube.com/channel/UCEImzXnR_ur7QaVv_l2YTCw?view_as=subscriber"
          className="share-button share-youtube-button"
        >
          <BsYoutube />
        </Button>
        <Button
          variant="text"
          target="_blank"
          href="https://twitter.com/Gmitstech"
          className="share-button share-twitter-button"
        >
          <BsTwitter />
        </Button>
        <Button
          variant="text"
          target="_blank"
          href="https://www.facebook.com/GMITSTECHLLC"
          className="share-button share-facebook-button"
        >
          <FaFacebookF />
        </Button>
        <Button
          variant="text"
          target="_blank"
          href="https://www.instagram.com/gmitstech/"
          className="share-button share-instagram-button"
        >
          <BsInstagram />
        </Button>
      </div>
      <div className="layout-header">
        <SubHeader />
        <div className="layout-navbar">
          <Navbar />
        </div>
      </div>
      <div className="layout-page-data-container">{props.children}</div>
      <Footer />
      <SubFooter />
    </div>
  );
};
export default Layout;
