import React from "react";
// styles
import "./../../styles/sub-header.css";
// material ui
import { Box, Grid, Button } from "@mui/material";
//icons
import { IoCall } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { ImWhatsapp } from "react-icons/im";
import { FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import { BsYoutube, BsTwitter, BsInstagram } from "react-icons/bs";
// router
import { useNavigate } from "react-router-dom";
const SubHeader = () => {
  const navigate = useNavigate();
  return (
    <div className="sub-header-container">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xl={2} lg={1} md={1} sm={0} xs={0}></Grid>
          <Grid item xl={4} lg={5} md={5} sm={12} xs={12}>
            <div className="sub-header-1st-section">
              <Button
                variant="text"
                href="tel:+971 4 236 7319"
                className="sub-header-call-button"
                disableRipple={true}
              >
                <IoCall className="sub-header-call-button-icon" /> +971 4 236
                7319
              </Button>
              <Button
                variant="text"
                href="mailto:info@gmitstech.com"
                className="sub-header-email-button"
                disableRipple={true}
              >
                <HiOutlineMail className="sub-header-email-button-icon" />
                info@gmitstech.com
              </Button>
            </div>
          </Grid>
          <Grid item xl={4} lg={5} md={5} sm={12} xs={12}>
            <div className="sub-header-2nd-section">
              <Button
                variant="text"
                target="_blank"
                href="//api.whatsapp.com/send?phone=+971 50 987 3721&text=Hi I am available"
                className="sub-header-social-button"
              >
                <ImWhatsapp />
              </Button>
              <Button
                variant="text"
                target="_blank"
                href="https://www.linkedin.com/in/gmitstech/"
                className="sub-header-social-button"
              >
                <FaLinkedinIn />
              </Button>
              <Button
                variant="text"
                target="_blank"
                href="https://www.youtube.com/channel/UCEImzXnR_ur7QaVv_l2YTCw?view_as=subscriber"
                className="sub-header-social-button"
              >
                <BsYoutube />
              </Button>
              <Button
                variant="text"
                target="_blank"
                href="https://twitter.com/Gmitstech"
                className="sub-header-social-button"
              >
                <BsTwitter />
              </Button>
              <Button
                variant="text"
                target="_blank"
                href="https://www.facebook.com/GMITSTECHLLC"
                className="sub-header-social-button"
              >
                <FaFacebookF />
              </Button>
              <Button
                variant="text"
                target="_blank"
                href="https://www.instagram.com/gmitstech/"
                className="sub-header-social-button"
              >
                <BsInstagram />
              </Button>
            </div>
          </Grid>
          <Grid item xl={2} lg={1} md={1} sm={0} xs={0}></Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default SubHeader;
